import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Container,
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { auth } from '../firebaseConfig'; // Import Firebase Auth
import SearchButton from './GlobalSearch';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedMenu, setSelectedMenu] = useState<string | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [user, setUser] = useState<any>(null); // State for authenticated user
  const [openDropdown, setOpenDropdown] = useState<string | null>(null); // For mobile dropdowns

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user); // Set the user if they are logged in, or null if not
    });

    return () => unsubscribe();
  }, []);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, menuName: string) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedMenu(menuName);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setProfileMenuAnchorEl(null);
    setSelectedMenu(null);
  };

  const handleMouseLeave = (event: React.MouseEvent) => {
    const relatedTarget = event.relatedTarget;
    if (
      !relatedTarget ||
      !(relatedTarget instanceof Node) ||
      !(event.currentTarget.contains(relatedTarget))
    ) {
      handleMenuClose();
    }
  };

  const handleDropdownClick = (menuName: string) => {
    setOpenDropdown(openDropdown === menuName ? null : menuName);
  };

  const dropdownItems: { [key: string]: { name: string; url: string }[] } = {
    Markets: [
      { name: 'Stocks', url: '/markets/stocks' },
      { name: 'Indices', url: '/markets/indices' },
      { name: 'Commodities', url: '/markets/commodities' },
      { name: 'Cryptos', url: '/markets/crypto' },
      { name: 'Forex', url: '/markets/forex' },
    ],
    News: [
      { name: 'Financial Markets', url: '/news?topic=financial_markets' },
      { name: 'Overview', url: '/news?topic=overview' },
      { name: 'Corporations', url: '/news?topic=corporations' },
      { name: 'Real Estate', url: '/news?topic=real_estate' },
      { name: 'Market News', url: '/news?topic=market_news' },
      { name: 'Stocks', url: '/news?topic=stocks' },
      { name: 'Crypto', url: '/news?topic=crypto' },
    ],
    Analysis: [
      { name: 'All', url: '/forum' },
      { name: 'Market Analysis', url: '/forum/Analysis' },
      { name: 'Forum', url: '/forum/Insights' },
    ],
    More: [
      { name: 'Education', url: '/coming-soon' },
      { name: 'About', url: 'about' },
    ],
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerList = () => (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
        <ListItem button component={Link} to="/">
          <ListItemText primary="Home" />
        </ListItem>

        {Object.keys(dropdownItems).map((item) => (
          <React.Fragment key={item}>
            <ListItem button onClick={() => handleDropdownClick(item)}>
              <ListItemText primary={item} />
              {openDropdown === item ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openDropdown === item} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {dropdownItems[item].map((submenu) => (
                  <ListItem button key={submenu.name} component={Link} to={submenu.url} sx={{ pl: 4 }}>
                    <ListItemText primary={submenu.name} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}

        <ListItem button component={Link} to="/coming-soon">
          <ListItemText primary="Calendar" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar position="sticky" sx={{ backgroundColor: '#2e003e', boxShadow: 'none' }}>
      <Container>
        <Toolbar sx={{ justifyContent: 'space-between', display: 'flex' }}>
          <Typography variant="h6" component={Link} to="/" sx={{ textDecoration: 'none', color: '#fff', fontWeight: 'bold' }}>
            Charts<span style={{ color: '#8e44ad' }}>Over</span>
          </Typography>

          {isMobile ? (
            <>
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
              <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                {drawerList()}
              </Drawer>
            </>
          ) : (
            <Box sx={{ display: 'flex', gap: 3 }}>
              {['Home', 'Markets', 'News', 'Analysis', 'More'].map((item) => (
                <div
                  key={item}
                  style={{ position: 'relative' }}
                  onMouseLeave={(event) => handleMouseLeave(event)}
                >
                  <Button
                    color="inherit"
                    onClick={(event) => dropdownItems[item] ? handleMenuOpen(event, item) : null}
                    sx={{
                      color: '#fff',
                      '&:hover': {
                        color: '#007bff',
                        backgroundColor: 'transparent',
                      },
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.5,
                    }}
                  >
                    {item}
                    {dropdownItems[item] && <KeyboardArrowDown />}
                  </Button>
                  {dropdownItems[item] && (
                    <Menu
                      anchorEl={menuAnchorEl}
                      open={selectedMenu === item}
                      onClose={handleMenuClose}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                      MenuListProps={{
                        onMouseEnter: () => setSelectedMenu(item),
                        onMouseLeave: handleMenuClose,
                      }}
                    >
                      {dropdownItems[item]?.map((submenu) => (
                        <MenuItem key={submenu.name} onClick={handleMenuClose}>
                          <Link to={submenu.url} style={{ textDecoration: 'none', color: 'inherit' }}>
                            {submenu.name}
                          </Link>
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                </div>
              ))}

              <Button
                color="inherit"
                component={Link}
                to="/coming-soon"
                sx={{
                  color: '#fff',
                  '&:hover': {
                    color: '#007bff',
                    backgroundColor: 'transparent',
                  },
                }}
              >
                Calendar
              </Button>
            </Box>
          )}

          <SearchButton />
          <div
            onMouseLeave={handleMouseLeave}
            onMouseEnter={handleProfileMenuOpen}
          >
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              sx={{ color: '#fff' }}
              onClick={handleProfileMenuOpen}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              anchorEl={profileMenuAnchorEl}
              open={Boolean(profileMenuAnchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              MenuListProps={{
                onMouseEnter: handleProfileMenuOpen,
                onMouseLeave: handleMenuClose,
              }}
            >
              {user ? (
                <>
                  <MenuItem component={Link} to="/profile" onClick={handleMenuClose}>My Profile</MenuItem>
                  <MenuItem onClick={() => { auth.signOut(); handleMenuClose(); }}>Logout</MenuItem>
                </>
              ) : (
                <>
                  <MenuItem component={Link} to="/login" onClick={handleMenuClose}>Login</MenuItem>
                  <MenuItem component={Link} to="/login" onClick={handleMenuClose}>Sign Up</MenuItem>
                </>
              )}
            </Menu>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
