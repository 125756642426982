import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC7uF44CAwek9x4XdjLSFcqe87dpPOa_34",
  authDomain: "chartsapp-333338.firebaseapp.com",
  projectId: "chartsapp-333338",
  storageBucket: "chartsapp-333338.appspot.com",
  messagingSenderId: "1002451065329",
  appId: "1:1002451065329:web:668dc5b342bc4155640c50",
  measurementId: "G-YBN4V0XZYY"
};



const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
// Initialize Firebase Auth
export const auth = getAuth(app);

// Initialize Firebase Storage
export const storage = getStorage(app);
