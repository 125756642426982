import React, { useEffect, useState } from 'react';
import { 
  TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Button, TextField, Toolbar, TablePagination, 
  Box, Typography 
} from '@mui/material';
import axios from 'axios';
import StockDrawer from './drawer'; // Adjust the path based on your folder structure
import Logo from './logo';

const MarketTable = () => {
  const [movers, setMovers] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState<'all' | 'gainers' | 'losers' | 'increase' | 'decrease'>('all');
  const [selectedSymbol, setSelectedSymbol] = useState<string | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Fetch movers data from the API
  const fetchMoversData = async () => {
    setLoading(true);
    setError(null);
    try {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const moversResponse = await axios.get(`${baseUrl}/movers-stock`);
      const combinedMovers = [
        ...moversResponse.data.gainers.map(gainer => ({ ...gainer, moverType: 'gainer' })),
        ...moversResponse.data.losers.map(loser => ({ ...loser, moverType: 'loser' }))
      ];

      const localStockInfoResponse = await fetch('/assets/allStockList.json');
      const localStockInfo = await localStockInfoResponse.json();

      // Merge API data with local JSON data based on the 'symbol' key
      const fullDataMovers = combinedMovers.map((mover) => {
        const localStockData = localStockInfo.find((stock) => stock.symbol === mover.symbol);
        return { ...mover, ...localStockData };
      });
      setMovers(fullDataMovers);
    } catch (err) {
      setError('Error fetching market data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMoversData();
  }, []);

  const handleOpenDrawer = (symbol: string) => {
    setSelectedSymbol(symbol);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedSymbol(null);
  };

  const handleFilterType = (type: 'all' | 'gainers' | 'losers' | 'increase' | 'decrease') => {
    setFilterType(type);
    setPage(0); // Reset pagination when filters change
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Apply search and filter logic
  const filteredMovers = movers.filter((mover) => {
    if (filterType === 'all') return true;
    if (filterType === 'gainers') return mover.moverType === 'gainer';
    if (filterType === 'losers') return mover.moverType === 'loser';
    if (filterType === 'increase') return mover.percent_change > 5;
    if (filterType === 'decrease') return mover.percent_change < -5;
    return true;
  });

  const searchedMovers = searchTerm
    ? filteredMovers.filter((mover) => 
        mover.name?.toLowerCase().includes(searchTerm.toLowerCase()) || 
        mover.symbol?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : filteredMovers;

  // Paginate the searched and filtered data
  const paginatedMovers = searchedMovers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box>
      <Typography variant="h2" component="h2" pb={2}>
        Stock Market Update
      </Typography>

      <TableContainer component={Paper} className="market-table">
        {/* Toolbar for Filters */}
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Button variant={filterType === 'all' ? 'contained' : 'outlined'} onClick={() => handleFilterType('all')} sx={{ marginRight: 1 }}>
              All
            </Button>
            <Button variant={filterType === 'gainers' ? 'contained' : 'outlined'} onClick={() => handleFilterType('gainers')} sx={{ marginRight: 1 }}>
              Gainers
            </Button>
            <Button variant={filterType === 'losers' ? 'contained' : 'outlined'} onClick={() => handleFilterType('losers')} sx={{ marginRight: 1 }}>
              Losers
            </Button>
            <Button variant={filterType === 'increase' ? 'contained' : 'outlined'} onClick={() => handleFilterType('increase')} sx={{ marginRight: 1 }}>
              &#62; 5% Increase
            </Button>
            <Button variant={filterType === 'decrease' ? 'contained' : 'outlined'} onClick={() => handleFilterType('decrease')} sx={{ marginRight: 1 }}>
              &#60; -5% Decrease
            </Button>
          </Box>
          
          {/* Search bar */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <TextField
              label="Search by Name or Symbol"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Box>
        </Toolbar>

        {loading ? (
          <Typography>Loading...</Typography>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Logo</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Symbol</TableCell>
                <TableCell>Exchange</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Percent Change</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedMovers.map((mover: any) => (
                <TableRow key={mover.symbol}>
                  <TableCell>
                    <Logo symbol={mover.symbol} market_type={"stock"} />
                  </TableCell>
                  <TableCell>{mover.name}</TableCell>
                  <TableCell>{mover.symbol}</TableCell>
                  <TableCell>{mover.exchange}</TableCell>
                  <TableCell>${mover.price.toFixed(2)}</TableCell>
                  <TableCell className={mover.percent_change > 0 ? 'percent-change-positive' : 'percent-change-negative'}>
                    {mover.percent_change.toFixed(2)}%
                  </TableCell>
                  <TableCell>
                    <Button variant="outlined" color="primary" onClick={() => handleOpenDrawer(mover.symbol)}>
                      View More
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        {/* Pagination */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={searchedMovers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <StockDrawer open={drawerOpen} onClose={handleCloseDrawer} symbol={selectedSymbol} cryptoID={undefined} />
    </Box>
  );
};

export default MarketTable;
