import React, { useState, useEffect } from "react";
import { Container, Grid2, Typography, Button, ButtonGroup } from "@mui/material";
import axios from "axios";
import { NewsResponse, Article } from "../types/news";  
import { useLocation } from "react-router-dom";
import ArticleItem from "./ArticleItem";

const categories = ["Financial Markets", "Overview", "Corporations", "Real Estate", "Stocks", "Crypto"];

// Cache for news articles
const newsCache: Record<string, { articles: Article[]; timestamp: number }> = {};
const CACHE_DURATION = 15 * 60 * 1000; // 15 minutes in milliseconds

interface NewsPageProps {
  maxResults?: number;
  defaultCategory?: string; // Optional prop for default category
}

const NewsPage = ({ maxResults, defaultCategory = "Overview" }: NewsPageProps) => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>(defaultCategory);
  const location = useLocation();

  // Helper function to generate the URL slug
  const getCategoryUrl = (category: string) => {
    return `/news?topic=${category.toLowerCase().replace(/\s+/g, "_")}`;
  };

  // Function to extract 'topic' query parameter from URL
  const getQueryParam = (param: string): string | null => {
    const params = new URLSearchParams(location.search);
    return params.get(param);
  };

  // Function to fetch news (fetches up to 500 articles at once)
  const fetchNews = async (category: string) => {
    try {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;

      // Constructing API URL based on category
      let url = `${baseUrl}/news?topics=${category.toLowerCase()}`;
      if (category === "Overview") {
        url = `${baseUrl}/news?topics&limit=500`;
      } else if (category === "Corporations") {
        url = `${baseUrl}/news?topics=mergers_and_acquisitions`;
      } else if (category === "Real Estate") {
        url = `${baseUrl}/news?topics=real_estate`;
      } else if (category === "Financial Markets") {
        url = `${baseUrl}/news?topics=financial_markets`;
      } else if (category === "Stocks") {
        url = `${baseUrl}/news?topics=ipo,earnings`;
      } else if (category === "Crypto") {
        url = `${baseUrl}/news?topics=blockchain`;
      }

      const response = (await axios.get<NewsResponse>(url)).data.newsResponse;

      const filteredArticles = response.filter((article: Article) => {
        const summaryLower = article.summary?.toLowerCase() || "";
        const titleLower = article.title?.toLowerCase() || "";
        // Exclude articles containing specific phrases in summary or title
        const excludedPhrases = ["before you continue", "another phrase to exclude"]; // Add more phrases as needed
  
        return !excludedPhrases.some(phrase => summaryLower.includes(phrase) || titleLower.includes(phrase));
      });

      // Store in cache
      newsCache[category] = { articles: filteredArticles, timestamp: Date.now() };

      setArticles(filteredArticles);
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  // Fetch news whenever the selected category changes
  useEffect(() => {
    const topic = getQueryParam("topic");
    if (!topic) return; // Prevent undefined processing

    const formattedTopic = topic.replace(/_/g, " ").toLowerCase();
    const matchingCategory = categories.find(category => category.toLowerCase() === formattedTopic);

    if (matchingCategory) {
      setSelectedCategory(matchingCategory); // Set only if found
    } else {
      setSelectedCategory(defaultCategory); // Use the default category if no match
    }
  }, [location.search, defaultCategory]);

  // Fetch news based on selected category
  useEffect(() => {
    if (selectedCategory) {
      // Check if data is cached and not expired
      const cachedData = newsCache[selectedCategory];
      if (cachedData && (Date.now() - cachedData.timestamp) < CACHE_DURATION) {
        setArticles(cachedData.articles);
      } else {
        fetchNews(selectedCategory);  // Fetch only if category is valid
      }
    }
  }, [selectedCategory]);

  // Limit the number of articles displayed based on maxResults prop
  const displayedArticles = maxResults ? articles.slice(0, maxResults) : articles;

  return (
    <Container sx={{ marginTop: "20px" }}>
      <Typography variant="h3" align="center" gutterBottom>
        News Dashboard
      </Typography>

      <Grid2 container spacing={2} sx={{ marginTop: "20px", justifyContent: 'center', 
              alignItems: 'center', }}>
      {categories.map((category) => (
        <Grid2  sx={{xs: 6}}key={category}>
          <Button
          variant="contained"
            href={getCategoryUrl(category)}
            fullWidth
          >
            {category}
          </Button>
        </Grid2>
      ))}
    </Grid2>

      {selectedCategory && (
        <Grid2 container sx={{ marginTop: "50px" }} spacing={3}>
          {displayedArticles.map((article, index) => (
            <Grid2 size={{ xs: 12, sm: 12, md: 4 }} key={index}>
              <ArticleItem article={article} />
            </Grid2>
          ))}
        </Grid2>
      )}
    </Container>
  );
};

export default NewsPage;
