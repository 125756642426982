import React from 'react';
import { Routes, Route } from 'react-router-dom'; // No need for BrowserRouter here anymore
import { Box, createTheme, ThemeProvider } from '@mui/material';
import Navbar from './components/navbar';
import HomePage from './components/HomePage';  // Import the new HomePage component
import NewsPage from './components/News'; 
import AuthForm from './components/login'; 
import ForumCategoryPage from './components/ForumCategoryPage';
import CreatePost from './components/createPost';
import PostView from './components/postView';
import MarketTableCommodities from './components/commoditiesMainPage';
import Footer from  './components/footer';
import ProfilePage from  './components/profilePage';
import AboutPage from './components/AboutPage';
import CryptoTable from './components/cryptoMainPage';
import ComingSoon from './components/comingSoon';

import './App.css';

const theme = createTheme({
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
    h2: {
      fontSize: '28px',
      fontWeight: 400,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: 1.2,
    },
  },
  palette: {
    text: {
      primary: '#FFFFFF',
      secondary: '#AAAAAA',
    },
    background: {
      paper: '#3a005c',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
          textTransform: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#4c007f',
          color: '#FFFFFF',
        },
      },
    },
  },
});

const MarketPage = ({ type }) => {
  return <MarketTableCommodities type={type} />;
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: '#2e003e', minHeight: '100vh' }}>
        <Navbar />

        <Routes>
          {/* Home Page Route */}
          <Route path="/" element={<HomePage />} />

          {/* News Page Route */}
          <Route path="/news" element={<NewsPage />} />

          <Route path="/login" element={<AuthForm />} />
          <Route path="/forum/Analysis" element={<ForumCategoryPage category="analysis" />} />
          <Route path="/forum/Insights" element={<ForumCategoryPage category="insights" />} />
          <Route path="/forum" element={<ForumCategoryPage category="All" />} />
        <Route path="/forum/ideas" element={<ForumCategoryPage category="Ideas" />} />
        <Route path="/post/:postId" element={<PostView />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/forum/create-post" element={<CreatePost />} />
        <Route path="/news/overview" element={<NewsPage limitPerPage={25} />} />
        <Route path="/markets/stocks" element={<MarketPage type="stocks" />} />
        <Route path="/markets/commodities" element={<MarketPage type="commodity" />} />
      <Route path="/markets/forex" element={<MarketPage type="forex" />} />
      <Route path="/markets/crypto" element={<CryptoTable type="crypto" />} />
      <Route path="/markets/indices" element={<MarketPage type="indices" />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/coming-soon" element={<ComingSoon />} />
      <Route path="/calendar" element={<ComingSoon />} />
      <Route path="/home" element={<HomePage />} />
        </Routes>
        <Box pt={10}>
          <Footer />
          </Box>
      </Box>
      
    </ThemeProvider>
  );
};

export default App;
